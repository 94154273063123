body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #265890;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex{
  display: flex;
}

.container {
  margin: 0 auto;
}

header {
  background-color: #1e90ff;
  padding: 20px 0;
  margin-bottom: 20px;
  text-align: center;
}

header h1 {
  color: #fff;
  margin: 0;
}

.content {
  background-color: #fff;
  min-height: 90vh;
}

.footer{
  width: 80%;
  background-color: #265890;
  color: #fff;
  margin: auto;
}

.btn_general{
  display: inline-block;
	padding: 1.375rem 2.125rem 1.375rem 2.125rem;
  margin: 1.375rem 1.375rem 0 0;
	border: 0.125rem solid #56afe4;
	border-radius: 0.7rem;
	background-color: #fff;
	color: #56afe4;
	font: 700 0.75rem/0 "Montserrat", sans-serif;
  font-size: 15px;
	text-decoration: none;
	transition: all 0.2s;
}
.btn_general:hover {
  background-color: #265890;
	color: #fff;
	text-decoration: none;
}

.btn_small{
    display: inline-block;
    padding: 0.5em;
    margin: 0.5em;
    border: 0.125rem solid #56afe4;
    border-radius: 0.7rem;
    background-color: #fff;
    color: #56afe4;
    font: 700 0.75rem/1 "Montserrat", sans-serif;
    text-decoration: none;
    transition: all 0.2s;
}
.btn_small:hover {
  background-color: #265890;
	color: #fff;
	text-decoration: none;
}

.item{
  padding-bottom: 1em;
}

.itemdiv{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  min-height: 600px;
  margin: auto;
  padding: 1em;
  background-color: #efefef;
  margin-bottom: 1em;
}

.itemdiv .itemleft{
  width: 600px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.itemdiv .itemleft img{
  max-width: 100%;
  height: auto;
}

.itemdiv .itemright{
  width: 600px;
  padding: 2em;
}

.headertop{
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 2em;
  align-items: center;
}

.headertop *{
  padding: 1em;
}

.gallerydiv{
  padding-bottom: 2em;
  width: 80%;
  margin: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.pagination{
  padding-bottom: 2em;
  margin: auto;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.signsdiv{
  display: flex;
}

.categories{
  width: 15%;
  padding: 0 10% 0 10%;
  display: flex;
  flex-direction: column;
}

.selected{
  background-color: #efefef;
}

.filterbutton{
  position: relative;
  left: 53px;
  top: 0px;
  display: none;
  padding: 0.5em 1em 0.5em 1em;
  margin: 0.5em;
  border: 0.125rem solid #265890;
  color: #265890;
  background-color: #fff;
  font: 700 0.75rem/1 "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

@media (max-width:700px) {
  .filterbutton{
    display: block;
  }

  .categories{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000000a6;
    padding: 0;
    top: 92px;
    z-index: 9998;
    display: none;
  }

  .categories .categoriescontent{
    margin: 0 0 50px 0;
    top: 153px;
    background-color: #fff;
    padding: 5%;
    z-index: 9999;
    position: absolute;
    width: 95%;
    height: auto;
    display: none;
  }

  .categories_expanded{
    display: block;
  }
  .categories .expanded{
    display: block;
  }
}

.tipsdiv{
  width: 80%;
  margin: auto;
  padding-bottom: 3em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.tipsdiv .btn_general{
  margin: 0;
}

.tipsdiv>div{
  width: 350px;
  margin: 0 1em 0 1em;
}

.subheader{
  background: #265890;
  border-left: 15px solid #1e90ff;
  padding: 10px 0px;
  height: auto;
  padding-left: 1em;
  margin-top: 1em;
}

.subheader h3{
  color: #fff;
  margin: 0;
  font-size: 13px;
}

div.gallery {
  height: 200px;
  margin: 0.7em;
  border: 1px solid #ccc;
  float: left;
  width: 180px;
  transition: all 0.5s;
}

div.gallery:hover {
  box-shadow: 1px 1px 5px grey;
}

div.gallery .imgdiv{
  margin: auto;
  width: 150px;
  height: 120px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
}

div.gallery img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
}

div.desc {
  text-decoration: none !important;
  padding: 15px;
  text-align: center;
}

@media (max-width:500px) {

  .gallerydiv{
    padding-bottom: 2em;
    width: 100%;
    margin: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
  }

  div.gallery {
    height: 200px;
    margin: 0.7em;
    border: 1px solid #ccc;
    float: left;
    width: 150px;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
  }
  
  div.gallery .imgdiv{
    margin: auto;
    width: 150px;
    height: 100px;
    overflow: hidden;
    position: relative;
  }
}

.faqdiv{
  padding-bottom: 1em;
  width: 80%;
  margin: auto;
}

.faqdiv>div {
  margin: 1em 0 1em 0;
  padding: 1em;
  background-color: #efefef;
}
.faqdiv>div>* {
  padding: 0 1em;
}

.faqdiv h3{
  margin-top: 0;
  font-size: 30px;
}

.faqdiv h4{
  color: #265890;
}

.login-form {
  height: 90vh;
  max-width: 400px;
  min-width: 200px;
  margin: 0 auto; 
  padding: 20px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.login-form button {
  width: 100%; 
  padding: 10px;
}

@media (max-width: 600px) {

  .login-form { 
    background-color: #265890;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center; 
  }

  .login-form input,
  .login-form button {
    padding: 5px;
    margin-bottom: 10px;
  }

}

.table {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex; 
  flex-direction: row;
}

.cell {
  flex: 1;
  padding: 8px 16px;
}

.header {
  font-weight: bold; 
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
  animation: fadeInOut 1s forwards;
}

.dialog-box {
  min-width: 200px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001; 
  cursor: pointer; 
  animation: fadeInOut 3s forwards;
}

.dialog-box.success {
  border: 2px solid green;
}

.dialog-box.error {
  border: 2px solid red;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; visibility: hidden; }
}

/* AddToCart.css */
.addtocartdiv {
  margin: auto;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: #fff;
}

.addtocartdiv h4 {
  text-align: center;
  color: #444;
  margin-bottom: 15px;
}

.addtocartdiv p {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.addtocartitem {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.addtocartitem span {
  margin-right: 10px;
}

.addtocartitem input,
.addtocartitem select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex-grow: 1;
}

.addtocartitem input[type='text'] {
  width: 100%;
}