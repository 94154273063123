

.homemain{
    height: 75vh;
    padding-top: 10%;

}

.homeleft{
    color: #fff;
    padding-left: 20%;
    width: 40%;

}

.homeleft > h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 60px;
    line-height: 0.7;
}

@media (max-width:1280px) {
    .homemain{
        min-height: 85vh;
    }
    .homeleft{
        padding-top: 20%;
        padding-left: 10%;
        width: 80%;
    }
    .homeleft > h1{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        line-height: 0.7;
    }
}

