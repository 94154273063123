.materials-display {
    width: 80%;
    text-align: center;
    margin: 70px auto 70px auto;
  }

  .materials-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
  }

  .material-card {
    color: #024695;
    width: 270px;
    border-left: 10px solid #1e90ff;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.378);
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }

  .material-card img {
    width: 90%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    transition: transform 0.3s ease;
  }

  .material-card:hover img {
    transform: scale(1.1);
  }

  .material-card ul {
    color: #fff;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: #024795a0; 
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); 
    z-index: 10; 
  }

  .material-card.show-benefits ul {
    max-height: 300px;
    line-height: 1.2;
    padding: 1em;
    text-align: left;
  }

  .material-card.show-benefits ul li {
    max-height: 300px;
    line-height: 1.2;
    padding: 0.5em;
    text-align: left;
  }

  .material-card.show-benefits ul li:first-child {
    font-weight: 800;
  }