.navbardiv{
  z-index: 1000;
}

.navigation {
  position: sticky;
  top: 0;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  background-color: #265890;
  color: #fff;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  z-index: 1000;
}

.brand-name {
  margin-left: 17%;
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 2rem;
}
.navigation-menu li a {
  color: #fff;
  font-size: 1.1em;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  width: 100%;
}

.menuimg {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.navbottom{
  width: 100%;
  height: 0px;
  background-color: #56afe4;
  position: sticky;
  top: 76px;
}


@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
  .menuimg {
    display: block;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  
}


/* .navbar {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    flex-direction: row;
    align-items: center;
    background-color: #265890;
  }
  .navright{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: baseline;
  }
  
  .nav-item {
    text-decoration: none;
    color: #fff;
    font-size: 1.1em;
    padding: 5px 10px;
  }
  
  .nav-item:hover {
    background-color: rgba(30, 144, 255, 0.1);
  }
  
  .nav-item-active {
    color: #ffffff;
    background-color: #1e90ff;
  }

  .navbottom{
    width: 100%;
    height: 8px;
    background-color: #56afe4;
  }
   */