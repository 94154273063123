body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #265890;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex{
  display: flex;
}

.container {
  margin: 0 auto;
}

header {
  background-color: #1e90ff;
  padding: 20px 0;
  margin-bottom: 20px;
  text-align: center;
}

header h1 {
  color: #fff;
  margin: 0;
}

.content {
  background-color: #fff;
  min-height: 90vh;
}

.footer{
  width: 80%;
  background-color: #265890;
  color: #fff;
  margin: auto;
}

.btn_general{
  display: inline-block;
	padding: 1.375rem 2.125rem 1.375rem 2.125rem;
  margin: 1.375rem 1.375rem 0 0;
	border: 0.125rem solid #56afe4;
	border-radius: 0.7rem;
	background-color: #fff;
	color: #56afe4;
	font: 700 0.75rem/0 "Montserrat", sans-serif;
  font-size: 15px;
	text-decoration: none;
	transition: all 0.2s;
}
.btn_general:hover {
  background-color: #265890;
	color: #fff;
	text-decoration: none;
}

.btn_small{
    display: inline-block;
    padding: 0.5em;
    margin: 0.5em;
    border: 0.125rem solid #56afe4;
    border-radius: 0.7rem;
    background-color: #fff;
    color: #56afe4;
    font: 700 0.75rem/1 "Montserrat", sans-serif;
    text-decoration: none;
    transition: all 0.2s;
}
.btn_small:hover {
  background-color: #265890;
	color: #fff;
	text-decoration: none;
}

.item{
  padding-bottom: 1em;
}

.itemdiv{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  min-height: 600px;
  margin: auto;
  padding: 1em;
  background-color: #efefef;
  margin-bottom: 1em;
}

.itemdiv .itemleft{
  width: 600px;
  display: flex;
  justify-content: center;
  margin: auto;
}

.itemdiv .itemleft img{
  max-width: 100%;
  height: auto;
}

.itemdiv .itemright{
  width: 600px;
  padding: 2em;
}

.headertop{
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 2em;
  align-items: center;
}

.headertop *{
  padding: 1em;
}

.gallerydiv{
  padding-bottom: 2em;
  width: 80%;
  margin: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.pagination{
  padding-bottom: 2em;
  margin: auto;
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.signsdiv{
  display: flex;
}

.categories{
  width: 15%;
  padding: 0 10% 0 10%;
  display: flex;
  flex-direction: column;
}

.selected{
  background-color: #efefef;
}

.filterbutton{
  position: relative;
  left: 53px;
  top: 0px;
  display: none;
  padding: 0.5em 1em 0.5em 1em;
  margin: 0.5em;
  border: 0.125rem solid #265890;
  color: #265890;
  background-color: #fff;
  font: 700 0.75rem/1 "Montserrat", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
}

@media (max-width:700px) {
  .filterbutton{
    display: block;
  }

  .categories{
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000000a6;
    padding: 0;
    top: 92px;
    z-index: 9998;
    display: none;
  }

  .categories .categoriescontent{
    margin: 0 0 50px 0;
    top: 153px;
    background-color: #fff;
    padding: 5%;
    z-index: 9999;
    position: absolute;
    width: 95%;
    height: auto;
    display: none;
  }

  .categories_expanded{
    display: block;
  }
  .categories .expanded{
    display: block;
  }
}

.tipsdiv{
  width: 80%;
  margin: auto;
  padding-bottom: 3em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.tipsdiv .btn_general{
  margin: 0;
}

.tipsdiv>div{
  width: 350px;
  margin: 0 1em 0 1em;
}

.subheader{
  background: #265890;
  border-left: 15px solid #1e90ff;
  padding: 10px 0px;
  height: auto;
  padding-left: 1em;
  margin-top: 1em;
}

.subheader h3{
  color: #fff;
  margin: 0;
  font-size: 13px;
}

div.gallery {
  height: 200px;
  margin: 0.7em;
  border: 1px solid #ccc;
  float: left;
  width: 180px;
  transition: all 0.5s;
}

div.gallery:hover {
  box-shadow: 1px 1px 5px grey;
}

div.gallery .imgdiv{
  margin: auto;
  width: 150px;
  height: 120px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
}

div.gallery img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s;
}

div.desc {
  text-decoration: none !important;
  padding: 15px;
  text-align: center;
}

@media (max-width:500px) {

  .gallerydiv{
    padding-bottom: 2em;
    width: 100%;
    margin: auto;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  
  }

  div.gallery {
    height: 200px;
    margin: 0.7em;
    border: 1px solid #ccc;
    float: left;
    width: 150px;
  }
  
  div.gallery:hover {
    border: 1px solid #777;
  }
  
  div.gallery .imgdiv{
    margin: auto;
    width: 150px;
    height: 100px;
    overflow: hidden;
    position: relative;
  }
}

.faqdiv{
  padding-bottom: 1em;
  width: 80%;
  margin: auto;
}

.faqdiv>div {
  margin: 1em 0 1em 0;
  padding: 1em;
  background-color: #efefef;
}
.faqdiv>div>* {
  padding: 0 1em;
}

.faqdiv h3{
  margin-top: 0;
  font-size: 30px;
}

.faqdiv h4{
  color: #265890;
}

.login-form {
  height: 90vh;
  max-width: 400px;
  min-width: 200px;
  margin: 0 auto; 
  padding: 20px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.login-form button {
  width: 100%; 
  padding: 10px;
}

@media (max-width: 600px) {

  .login-form { 
    background-color: #265890;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center; 
  }

  .login-form input,
  .login-form button {
    padding: 5px;
    margin-bottom: 10px;
  }

}

.table {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex; 
  flex-direction: row;
}

.cell {
  flex: 1 1;
  padding: 8px 16px;
}

.header {
  font-weight: bold; 
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
  animation: fadeInOut 1s forwards;
}

.dialog-box {
  min-width: 200px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001; 
  cursor: pointer; 
  animation: fadeInOut 3s forwards;
}

.dialog-box.success {
  border: 2px solid green;
}

.dialog-box.error {
  border: 2px solid red;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; visibility: hidden; }
}

/* AddToCart.css */
.addtocartdiv {
  margin: auto;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: #fff;
}

.addtocartdiv h4 {
  text-align: center;
  color: #444;
  margin-bottom: 15px;
}

.addtocartdiv p {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.addtocartitem {
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.addtocartitem span {
  margin-right: 10px;
}

.addtocartitem input,
.addtocartitem select {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex-grow: 1;
}

.addtocartitem input[type='text'] {
  width: 100%;
}
.navbardiv{
  z-index: 1000;
}

.navigation {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem;
  background-color: #265890;
  color: #fff;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  z-index: 1000;
}

.brand-name {
  margin-left: 17%;
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
  list-style-type: none;
  margin: 0 2rem;
}
.navigation-menu li a {
  color: #fff;
  font-size: 1.1em;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  width: 100%;
}

.menuimg {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.navbottom{
  width: 100%;
  height: 0px;
  background-color: #56afe4;
  position: -webkit-sticky;
  position: sticky;
  top: 76px;
}


@media screen and (max-width: 768px) {
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    position: absolute;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 77px);
    background-color: white;
    border-top: 1px solid black;
  }
  .navigation-menu li {
    text-align: center;
    margin: 0;
  }
  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .navigation-menu li:hover {
    background-color: #eee;
  }
  .menuimg {
    display: block;
  }
  .navigation-menu.expanded ul {
    display: block;
  }
  
}


/* .navbar {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    flex-direction: row;
    align-items: center;
    background-color: #265890;
  }
  .navright{
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: baseline;
  }
  
  .nav-item {
    text-decoration: none;
    color: #fff;
    font-size: 1.1em;
    padding: 5px 10px;
  }
  
  .nav-item:hover {
    background-color: rgba(30, 144, 255, 0.1);
  }
  
  .nav-item-active {
    color: #ffffff;
    background-color: #1e90ff;
  }

  .navbottom{
    width: 100%;
    height: 8px;
    background-color: #56afe4;
  }
   */


.homemain{
    height: 75vh;
    padding-top: 10%;

}

.homeleft{
    color: #fff;
    padding-left: 20%;
    width: 40%;

}

.homeleft > h1{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 60px;
    line-height: 0.7;
}

@media (max-width:1280px) {
    .homemain{
        min-height: 85vh;
    }
    .homeleft{
        padding-top: 20%;
        padding-left: 10%;
        width: 80%;
    }
    .homeleft > h1{
        font-family: Arial, Helvetica, sans-serif;
        font-size: 30px;
        line-height: 0.7;
    }
}


.materials-display {
    width: 80%;
    text-align: center;
    margin: 70px auto 70px auto;
  }

  .materials-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 16px;
    gap: 16px;
  }

  .material-card {
    color: #024695;
    width: 270px;
    border-left: 10px solid #1e90ff;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.378);
    cursor: pointer;
    overflow: hidden;
    position: relative;
  }

  .material-card img {
    width: 90%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    transition: transform 0.3s ease;
  }

  .material-card:hover img {
    transform: scale(1.1);
  }

  .material-card ul {
    color: #fff;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: #024795a0; 
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); 
    z-index: 10; 
  }

  .material-card.show-benefits ul {
    max-height: 300px;
    line-height: 1.2;
    padding: 1em;
    text-align: left;
  }

  .material-card.show-benefits ul li {
    max-height: 300px;
    line-height: 1.2;
    padding: 0.5em;
    text-align: left;
  }

  .material-card.show-benefits ul li:first-child {
    font-weight: 800;
  }
.cartItem{
    a{
        width: 25%;
    }
    .imgDiv{
        display: flex;
        margin: auto;
        justify-content: center;
    }
    img{
        height: 100px;
        margin: auto;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    padding: 1em;
    background-color: #efefef;
    margin-bottom: 1em;

    h4{
        text-align: center;
        margin: 0.5em 0 0 0;
    }
    .cartItemBtn{
        display: flex;
        p{
            margin: 0.5em 0;
        }
    }
    .cartItemBtn div{
        margin: 0.5em 1em;
    }
    
}

.cartItemRight{
    width: 70%;
    padding: 0.5em;
}
.cartItemUpper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cartItemLower{
    p{
        margin: 0;
    }
}

.cartItemLowerSub{
    margin: 0.5em 0;
    display: flex ;
    p{
        margin: 0 1em 0 0;
    }
}


@media (max-width:800px) {
    
    .cartItem{
        display: none;
        img{
            height: 100px;
            margin: auto;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
        margin: auto;
        padding: 1em;
        background-color: #efefef;
        margin-bottom: 1em;

        h4{
            width: 100%;
            text-align: center;
            margin: 1em 0 0 0;
        }
        .cartItemBtn{
            display: flex;
            margin: auto;
            p{
                margin: 1em 0;
            }
        }
        .cartItemBtn div{
            margin: 1em 1em;
        }
        a{
            width: 100%;
        }
        
    }
    .cartItemUpper{
        display: flex;
        flex-wrap: wrap;

    }

    .cartItemLower{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .cartItemLowerSub{
        display: flex ;
        justify-content: center;
        margin: auto;
        p{
            margin: 0 1em;
        }
    }
    .cartItemRight{
        width: 90%;
        padding: 0.5em;
    }

}

.emptyCart{
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 2em;
    align-items: center;
    flex-direction: column;
    img{
        margin: auto;
        height: 200px;
        width: auto;
    }
    .btn_general{
        margin: 1em;
    }

}
.contactFormOutter {
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
}

.contactForm {
    width: calc(100% - 1em);
    margin: 0 auto;
}

.formItem {
    margin-bottom: 1em;
}

.cartItemLowerSub{
    p{
        margin: 0 1em 0 0 !important;
    }
}

.formItem p {
    margin: 0 0 0.5em 0;
}

.formItem input[type="text"],
.formItem input[type="phone"],
.formItem input[type="email"],
.formItem textarea {
    width: 100%;
    box-sizing: border-box; 
    padding: 0.5em;
}

.btn_general {
    cursor: pointer;
    padding: 1em;
    margin-top: 1em;
}


@media (max-width: 700px) {
    .contactFormOutter {
        padding: 1em;
    }

    .contactForm {
        width: calc(100% - 2em);
    }
}

.contactFormOutter {
    opacity: 1;
    transition: height 0.5s ease, opacity 0.5s ease;
}

.hidden {
    opacity: 0;
}


.fade-in {
    display: none;
    opacity: 0; 
    transition: opacity 1s ease-in; 
}

.fade-in.active {
    position: absolute;
    opacity: 1; 
    margin: auto;
    display: flex;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    flex-direction: column;
    align-items: center;
}
.footer {
    background-color: #265890;
    color: #fff;
    padding: 1rem 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .footer-section {
    flex: 1 1;
    min-width: 200px;
    margin: 1rem;
  }
  
  .footer-logo {
    width: 100px;
  }
  
  .footer h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .footer ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer li {
    margin-bottom: 0.5rem;
  }
  
  .footer a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 0.8rem;
  }
