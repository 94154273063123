.footer {
    background-color: #265890;
    color: #fff;
    padding: 1rem 0;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
    margin: 1rem;
  }
  
  .footer-logo {
    width: 100px;
  }
  
  .footer h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .footer ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer li {
    margin-bottom: 0.5rem;
  }
  
  .footer a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    font-size: 0.8rem;
  }