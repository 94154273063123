.contactFormOutter {
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
}

.contactForm {
    width: calc(100% - 1em);
    margin: 0 auto;
}

.formItem {
    margin-bottom: 1em;
}

.cartItemLowerSub{
    p{
        margin: 0 1em 0 0 !important;
    }
}

.formItem p {
    margin: 0 0 0.5em 0;
}

.formItem input[type="text"],
.formItem input[type="phone"],
.formItem input[type="email"],
.formItem textarea {
    width: 100%;
    box-sizing: border-box; 
    padding: 0.5em;
}

.btn_general {
    cursor: pointer;
    padding: 1em;
    margin-top: 1em;
}


@media (max-width: 700px) {
    .contactFormOutter {
        padding: 1em;
    }

    .contactForm {
        width: calc(100% - 2em);
    }
}

.contactFormOutter {
    opacity: 1;
    transition: height 0.5s ease, opacity 0.5s ease;
}

.hidden {
    opacity: 0;
}


.fade-in {
    display: none;
    opacity: 0; 
    transition: opacity 1s ease-in; 
}

.fade-in.active {
    position: absolute;
    opacity: 1; 
    margin: auto;
    display: flex;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    flex-direction: column;
    align-items: center;
}