.cartItem{
    a{
        width: 25%;
    }
    .imgDiv{
        display: flex;
        margin: auto;
        justify-content: center;
    }
    img{
        height: 100px;
        margin: auto;
    }
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    padding: 1em;
    background-color: #efefef;
    margin-bottom: 1em;

    h4{
        text-align: center;
        margin: 0.5em 0 0 0;
    }
    .cartItemBtn{
        display: flex;
        p{
            margin: 0.5em 0;
        }
    }
    .cartItemBtn div{
        margin: 0.5em 1em;
    }
    
}

.cartItemRight{
    width: 70%;
    padding: 0.5em;
}
.cartItemUpper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cartItemLower{
    p{
        margin: 0;
    }
}

.cartItemLowerSub{
    margin: 0.5em 0;
    display: flex ;
    p{
        margin: 0 1em 0 0;
    }
}


@media (max-width:800px) {
    
    .cartItem{
        display: none;
        img{
            height: 100px;
            margin: auto;
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
        margin: auto;
        padding: 1em;
        background-color: #efefef;
        margin-bottom: 1em;

        h4{
            width: 100%;
            text-align: center;
            margin: 1em 0 0 0;
        }
        .cartItemBtn{
            display: flex;
            margin: auto;
            p{
                margin: 1em 0;
            }
        }
        .cartItemBtn div{
            margin: 1em 1em;
        }
        a{
            width: 100%;
        }
        
    }
    .cartItemUpper{
        display: flex;
        flex-wrap: wrap;

    }

    .cartItemLower{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .cartItemLowerSub{
        display: flex ;
        justify-content: center;
        margin: auto;
        p{
            margin: 0 1em;
        }
    }
    .cartItemRight{
        width: 90%;
        padding: 0.5em;
    }

}

.emptyCart{
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 2em;
    align-items: center;
    flex-direction: column;
    img{
        margin: auto;
        height: 200px;
        width: auto;
    }
    .btn_general{
        margin: 1em;
    }

}